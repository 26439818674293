import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import Interesting from "../../components/interesting/Interesting";
import PersonalInfo from "../../components/personalInfo/PersonalInfo";
import Profile from "../../components/profile/Profile";
import ReferalStats from "../../components/referelStats/ReferalStats";
// import TooltipsProfile from "../../components/tooltipsProfile/TooltipsProfile";
import classes from "./ProfilePage.module.css";
import { useTelegram } from "../../hooks/useTelegram";
import { setPath } from "../../store/actions/appActions";
import GridBotLink from "../../components/gridBotLink/GridBotLink";
import PayFromIcon from "../../image/FromPay.svg";
import Modal from "../../components/modal/Modal";
import ModalDepProfile from "../../components/modalsGrid/ModalDepProfile";

const ProfilePage = ({ userBack, user }) => {
  const { webApp } = useTelegram();
  const dispatch = useDispatch();
  dispatch(setPath("one"));

  const theme = webApp.colorScheme;
  const userName = `${user.first_name} ${user.last_name}`;
  const nick = user.username;
  const refValue = window
    .btoa(user?.id + "")
    .split("")
    .map((i) => (i === "=" ? "" : i))
    .join("");

  const [showModal, setShowModal] = useState(false);

  return (
    <div className={classes.wrapper}>
      <Profile
        refValue={refValue}
        userName={userName}
        theme={theme}
        nick={nick}
        avatar={userBack?.avatar}
        balance={userBack?.balance}
        subscription_name={userBack?.subscription_name}
      />
      {/* <TooltipsProfile /> */}
      <GridBotLink
        imgUrl={PayFromIcon}
        name="Активные заявки на вывод"
        theme={theme}
        handleClick={() => setShowModal(true)}
      />
      <PersonalInfo theme={theme} user={userBack ?? {}} />
      {/* <Interesting theme={theme} /> */}
      <ReferalStats theme={theme} />
      {showModal && (
        <Modal onCloseModal={() => setShowModal(false)} theme={theme}>
          <ModalDepProfile
            theme={theme}
            title={"Активные заявки на вывод средств"}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProfilePage;
