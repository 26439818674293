import classes from "./ModalsGrid.module.css";
import GridBotImage from "../../image/GridBot.svg";
import styled from "styled-components";

const ModalBlockSystem = ({ theme }) => {
  return (
    <div className={classes.wrapper}>
      <img src={GridBotImage} alt="PaymentCard" />
      <Title theme={theme} className={classes.title}>
        Доступ к Grid-боту закрыт
      </Title>
      <Text>
        Для получения доступа Вам необходимо зарегистрироваться на платформе
        "Дело" по ссылке <span>@delo_app_bot</span> и приобрести тарифы{" "}
        <span>"Стратег"</span> или <span>"Лидер"</span>.
      </Text>
      <Submit
        href="tg://resolve?domain=@delo_app_bot"
        target="_blank"
        rel="noopener noreferrer"
      >
        Начать регистрацию
      </Submit>
    </div>
  );
};

export default ModalBlockSystem;

const Title = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
`;

const Text = styled.input`
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 12px;

  span {
    font-weight: 600;
  }
`;

const Submit = styled.a`
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 90%;
  padding: 9px;
  background: #beff8b;
  border-radius: 20px;
  margin-top: 15px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 0px gray;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 2px 20px gray;
  }
`;
