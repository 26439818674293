import "./App.css";
import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ProfilePage from "./pages/profilePage/ProfilePage";
import GridBotPage from "./pages/gridBotPage/GridBotPage";
import { useTelegram } from "./hooks/useTelegram";
// import { text } from "./translate";
import $api from "./http";

const App = () => {
  const { webApp, user } = useTelegram();
  const navigate = useNavigate();

  const onMainClick = useCallback(() => {
    window.location.href = "https://t.me/Generatoridey";
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const onBackClick = useCallback(() => {
    if (window.location.pathname === "/") {
      webApp.BackButton.hide();
    }
    webApp.MainButton.hide();
    navigate(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webApp.BackButton, window.location.pathname]);

  const [userBack, setUserBack] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const responseUserData = await $api.get("/me");
    setUserBack(responseUserData.data);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const responseUserData = await $api.get("/me");
        setUserBack(responseUserData.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    webApp.ready();
    webApp.enableClosingConfirmation();
    webApp.expand();
    webApp.BackButton.onClick(onBackClick);
    webApp.MainButton.setText("Получить консультацию");
    webApp.MainButton.onClick(onMainClick);

    return () => {
      webApp.BackButton.offClick(onBackClick);
      webApp.MainButton.offClick(onMainClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webApp]);

  return (
    <div className="App">
      <Routes>
        <Route
          index
          element={<GridBotPage userBack={userBack} user={user} />}
        />
        <Route
          path="/profile"
          element={<ProfilePage userBack={userBack} user={user} />}
        />
      </Routes>
    </div>
  );
};

export default App;
