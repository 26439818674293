import classes from "./ReferalUser.module.css";

const ReferalUser = ({ created_at, amount, theme }) => {
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };
  return (
    <div className={classes.wrapper}>
      <div
        className={classes.date}
        style={{ color: theme === "dark" ? "#fff" : "#000" }}
      >
        {formatDate(created_at)}
      </div>
      <div
        className={classes.pay}
        style={{ color: theme === "dark" ? "#fff" : "#000" }}
      >
        + {amount} USDT
      </div>
    </div>
  );
};

export default ReferalUser;
