import { useEffect, useState } from "react";
import classes from "./ReferalStats.module.css";
import ReferalUser from "./ReferalUser/ReferalUser";
import styled from "styled-components";
import $api from "../../http";

const ReferalStats = ({ theme }) => {
  const [winReq, setWinReq] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const responseRefrData = await $api.get("/user_cash_accruals");
    setWinReq(responseRefrData.data);
  }, []);

  return (
    <Wraper className={classes.wrapper + " wrapper"}>
      <Title theme={theme}>Статистика доходов</Title>
      {!!winReq?.length ? (
        <>
          <HeaderTable>
            <p>Дата</p>
            <p>Сумма</p>
          </HeaderTable>
          {winReq?.slice(0, 10).map((item) => (
            <ReferalUser key={item?.id} {...item} theme={theme} />
          ))}
          {winReq.length > 10 && (
            <ShowCount theme={theme}>
              Показано 10 операций из {winReq.length}
            </ShowCount>
          )}
        </>
      ) : (
        <NoRef theme={theme}>Нет ни одной записи о доходах!</NoRef>
      )}
    </Wraper>
  );
};

export default ReferalStats;
const NoRef = styled.div`
  margin: 5px auto;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#11141b")};
`;

const ShowCount = styled.div`
  width: 100%;
  text-align: right;
  font-size: 13px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -0.02em;
  margin-top: 22px;
  color: #5f7385;
`;

const HeaderTable = styled.div`
  padding-top: 15px;
  font-size: 9px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.02em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5f7385;
  p {
    margin: 0px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: -0.02em;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#11141b")};
`;

const Wraper = styled.div`
  border-radius: 16px;
  padding: 29px 14px;
  width: 86%;
  margin-top: 40px;
`;
