//appMiddleware.jsx
//Фунционал связанный с настройкой вида приложения и привязкой функций для кнопок
//Не обрабатывает данные
import {
  GO_TO_PROFILE_PAGE,
  GO_TO_MAIN_PAGE,
  INIT_APP,
  BIND_BUTTONS,
  UPDATE_USER_INFO,
  goToMainPage,
} from "../actions/appActions";
import { bindBtns } from "../thunks/appThunks";
import { updatePersonalInfo } from "../thunks/personalInfoThunks";

const appMiddleware = (useCloudStorageResult, useTelegramResult) => {
  let backToMainPageHandler = null;

  return (store) => (next) => (action) => {
    const { dispatch, getState } = store;
    const { webApp } = useTelegramResult;
    const state = getState();

    const backToMainPage = async () => {
      const path = state?.app?.path;
      if (path) {
        console.log(path);
        if (path === "one") {
          dispatch(goToMainPage());
          window.location.href = "/";
        } else {
          console.log("another block");
          window.location.href = path;
        }
      }
    };

    const handleBackToMainPage = () =>
      backToMainPageHandler && backToMainPageHandler();

    backToMainPageHandler = backToMainPage;

    if (action) {
      switch (action?.type) {
        case INIT_APP:
          webApp.ready();
          webApp.expand();
          webApp.MainButton.hide();
          webApp.BackButton.hide();
          dispatch(bindBtns());
          if (window.location.pathname !== "/") {
            webApp.BackButton.show();
          }
          break;
        case BIND_BUTTONS:
          break;
        // case BIND_MAIN_BTN:
        //    break
        // case BIND_BACK_BTN:
        //     webApp.BackButton.onClick(handleBackToMainPage);
        //     break;
        case GO_TO_MAIN_PAGE:
          webApp.BackButton.hide();
          break;
        case GO_TO_PROFILE_PAGE:
          webApp.BackButton.show();
          break;
        case UPDATE_USER_INFO:
          dispatch(
            updatePersonalInfo(
              useCloudStorageResult,
              useTelegramResult,
              action?.payload
            )
          );
          break;
        default:
          break;
      }
      return next(action);
    }
  };
};

export default appMiddleware;
