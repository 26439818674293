import { GO_TO_MAIN_PAGE, GO_TO_PROFILE_PAGE, INIT_APP,
    BIND_BUTTONS, BIND_MAIN_BTN, BIND_BACK_BTN,
    SAVE_USER_INFO, UPDATE_USER_INFO, SET_PATH } from "../actions/appActions";

const initialState = {
    email: null,
    role: null,
    avatarUrl: null,
    firstName: null,
    lastName: null,
    surname: null,
    company: null,
    balance: null,
    error: null,
    refs: null,
    subscription: null,
    path: null,
}

const appReducer = (state = initialState, action) => {
    switch(action?.type){
        case SET_PATH:
            console.log(action.payload);
            return{
                ...state,
                path: action.payload,
            }
        case INIT_APP:
            return{
                ...state,
            };
        case BIND_BUTTONS:
            return{
                ...state,
            };
        case BIND_MAIN_BTN:
            return{
                ...state,
            };
        case BIND_BACK_BTN:
            return{
                ...state,
            };
        case GO_TO_MAIN_PAGE:
            return {
                ...state,
            };
        case GO_TO_PROFILE_PAGE:
            return {
                ...state,
            };
        case SAVE_USER_INFO:
            const {email, role, avatarUrl, firstName, lastName, surname, company, balance, refs, subscription} = action.payload;
            return{
                ...state,
                email: email,
                role: role,
                avatarUrl: avatarUrl,
                firstName: firstName,
                lastName: lastName,
                surname: surname,
                company: company,
                balance: balance,
                refs: refs,
                subscription: subscription
            };
        case UPDATE_USER_INFO:
            const { newFirstName, newLastName, newSurname, newCompany} = action.payload;
            return{
                ...state,
                firstName: newFirstName,
                lastName: newLastName,
                surname: newSurname,
                company: newCompany,
            };
        default:
        return state;
    }
}
export default appReducer;