// middleware/index.js
import appMiddleware from "./appMiddleware";

const combinedMiddleware = (cloudStorageResult, telegramResult) => {
  return (store) => (next) => (action) => {
    console.log(action?.type);
    appMiddleware(cloudStorageResult, telegramResult)(store)(next)(action);
  };
};

export default combinedMiddleware;
