import classes from "./ModalsGrid.module.css";
import Wallet from "../../image/WalletImage.svg";
import styled from "styled-components";

const ModalBalanceInfo = ({ theme, balance, cash_accruals }) => {
  return (
    <div className={classes.wrapper}>
      <img src={Wallet} alt="wallet" />
      <Title theme={theme} className={classes.title}>
        Ваш баланс
      </Title>
      <Balance theme={theme} className={classes.priceText}>
        <span>Тело бота</span>
        {balance} USDT
      </Balance>
      <Balance theme={theme} className={classes.priceText}>
        <span>Проценты</span>
        {cash_accruals} USDT
      </Balance>
    </div>
  );
};

export default ModalBalanceInfo;

const Title = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
  font-size: 28px;
  font-weight: 700;
  line-height: 30.8px;
  letter-spacing: -0.02em;
`;

const Balance = styled.div`
  margin-top: 10px;
  font-family: system-ui;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -0.02em;

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: -0.02em;
    margin-right: 16px;
  }
`;
