import { combineReducers } from "redux";
import appReducer from "./appReducer";
const hash = window.location.hash.slice(1);
const tgWebAppPlatformRegex = /tgWebAppPlatform=([^&]*)/;
const matchTgWebAppPlatform = hash.match(tgWebAppPlatformRegex);

if (matchTgWebAppPlatform) {
  const tgWebAppPlatform = matchTgWebAppPlatform[1];
}

const rootReducer = combineReducers({
  app: appReducer,
});

export default rootReducer;
