import classes from "./ModalsGrid.module.css";
import PaymentCard from "../../image/PaymentCard.svg";
import styled from "styled-components";
import { useState } from "react";
import $api from "../../http";

const ModalFromCash = ({ theme, onCloseModal }) => {
  const [value, setValue] = useState(null);
  const [valueAddress, setValueAddress] = useState(null);
  const [natification, setNatification] = useState("");

  const clearData = () => {
    setValueAddress(null);
    setValue(null);
  };

  const handleBtn = async () => {
    if (!value?.length) {
      setNatification("Введите сумму!");
    } else if (!valueAddress?.length) {
      setNatification("Введите адрес кошелька!");
    } else {
      try {
        const { data } = await $api.post(`/withdrawal_request`, {
          amount: value,
          type: "TOP_UP",
          address: valueAddress,
        });

        console.log("data", data);
        setNatification("Запрос на вывод успешно отправлен!");
        clearData();
        setTimeout(() => {
          onCloseModal();
        }, 1000);
      } catch (error) {
        console.log("Ошибка запроса:", error?.response?.data?.error);
        if (error?.response?.data?.error.key === "not_enough_funds") {
          setNatification("Не достаточно средвств на балансе!");
          clearData();
        } else if (
          error?.response?.data?.error.key === "max_number_withdrawal_requests"
        ) {
          setNatification("Превышено кол-во выводов за последний месяц!");
          clearData();
        } else {
          setNatification("Что-то полшло не так! Попробуйте позже!");
          clearData();
        }
      }
    }

    setTimeout(() => {
      setNatification("");
      clearData();
    }, 3000);
  };
  return (
    <div className={classes.wrapper}>
      <img src={PaymentCard} alt="PaymentCard" />
      <Title theme={theme} className={classes.title}>
        Вывести средства
      </Title>
      <Input
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="100 $"
      />
      <Input
        type="string"
        value={valueAddress}
        onChange={(e) => setValueAddress(e.target.value)}
        placeholder="Адрес кошелька для вывода"
      />
      <Submit onClick={() => handleBtn()}>Отправить запрос</Submit>
      {natification && (
        <Natification theme={theme} className={classes.natification}>
          {natification}
        </Natification>
      )}
    </div>
  );
};

export default ModalFromCash;

const Natification = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  position: fixed;
  bottom: 5px;
  right: 20px;
  ${(props) =>
    props.theme === "light"
      ? "background: #000; color: #fff;"
      : "background: #fff; color: #000;"}
`;

const Title = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
`;

const Input = styled.input`
  padding: 10px;
  width: 90%;
  border-radius: 20px;
  border: none;
  background: #f1f1f1;
  margin-top: 20px;
  text-align: center;
  font-family: system-ui;
`;

const Submit = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 90%;
  padding: 9px;
  background: #beff8b;
  border-radius: 20px;
  margin-top: 15px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 0px gray;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 2px 20px gray;
  }
`;
