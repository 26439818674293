import classes from "./ModalsGrid.module.css";
import PaymentCard from "../../image/PaymentCard.svg";
import styled from "styled-components";
import { useEffect, useState } from "react";
import $api from "../../http";

const ModalDepProfile = ({ onCloseModal, title, theme }) => {
  const [amountBalance, setAmountBalance] = useState(null);
  const [withdrawalRequest, setWithdrawalRequest] = useState();

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    const fetchWithdrawalRequests = async () => {
      try {
        const responseUserData = await $api.get("/withdrawal_request");
        setWithdrawalRequest(responseUserData.data);
      } catch (error) {
        console.error("Error fetching withdrawal requests", error);
      }
    };

    fetchWithdrawalRequests();
  }, []);

  const paymentFn = async () => {
    const responce = await $api.post("/top_up_balance", {
      amount: amountBalance,
    });

    if (responce?.statusText === "OK" || responce?.status === 200) {
      const paymentUrl = responce?.data?.payment_url;

      if (paymentUrl) {
        window.open(paymentUrl, "_blank");
      }
    }
    onCloseModal();
  };

  return (
    <div className={classes.wrapper}>
      <img src={PaymentCard} alt="PaymentCard" />
      <Title theme={theme} className={classes.title}>
        {title ? (
          title
        ) : (
          <>
            Пополнить баланс
            <br />
            Пополнение баланса занимает до 5 минут!
          </>
        )}
      </Title>
      {title ? (
        <>
          {!!withdrawalRequest?.length ? (
            <>
              <AppTitleWrapper>
                <div>Статус заявки</div>
                <div>Дата</div>
                <div>Сумма</div>
              </AppTitleWrapper>
              {withdrawalRequest.map((item) => (
                <AppItem key={item.id}>
                  <StatusItem status={item.status}>
                    {item.status === "REQUEST"
                      ? "Заявка создана"
                      : item.status === "APPROVE"
                      ? "Подтвержден"
                      : "Отклонен"}
                  </StatusItem>
                  <ItemValue theme={theme}>
                    {formatDate(item.created_at)}
                  </ItemValue>
                  <ItemValue theme={theme}>{item.amount} USDT</ItemValue>
                </AppItem>
              ))}
            </>
          ) : (
            <NotHaveItem theme={theme}>У вас нет активных заявок.</NotHaveItem>
          )}
        </>
      ) : (
        <>
          <Input
            type="number"
            value={amountBalance}
            onChange={(e) => setAmountBalance(e.target.value)}
            placeholder="100 $"
          />
          <Submit onClick={paymentFn}>Отправить запрос</Submit>
        </>
      )}
    </div>
  );
};

export default ModalDepProfile;

const ItemValue = styled.div`
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
`;

const StatusItem = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-wrap: nowrap;
  letter-spacing: -0.02em;
  text-align: center;
  background: ${(props) =>
    props.status === "REQUEST"
      ? "#ffd000"
      : props.status === "APPROVE"
      ? "#BEFF8B"
      : "red"};
  border-radius: 8px;
  height: 24px;
  width: 104px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -0.02em;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
`;
const NotHaveItem = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
  font-size: 16px;
  margin-top: 14px;
`;
const AppTitleWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #5f7385;
  margin-top: 10px;
  width: 100%;
  font-size: 13px;
`;

const Title = styled.div`
  text-align: center;
  margin-top: 10px;
  color: ${(props) => (props.theme === "dark" ? "#fff" : "#000")};
`;

const Input = styled.input`
  padding: 10px;
  width: 90%;
  border-radius: 20px;
  border: none;
  background: #f1f1f1;
  margin-top: 20px;
  text-align: center;
  font-family: system-ui;
`;

const Submit = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 90%;
  padding: 9px;
  background: #beff8b;
  border-radius: 20px;
  margin-top: 15px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 0px gray;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 2px 20px gray;
  }
`;
