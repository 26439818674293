export const INIT_APP = "INIT";
export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const GO_TO_PROFILE_PAGE = "GO_TO_PROFILE_PAGE";
export const GO_TO_MAIN_PAGE = "GO_TO_MAIN_PAGE";
export const BIND_BUTTONS = "BIND_BUTTONS";
export const BIND_MAIN_BTN = "BIND_MAIN_BTN";
export const BIND_BACK_BTN = "BIND_BACK_BTN";
export const SET_PATH = "SET_PATH";

export const setPath = (path) => ({
  type: SET_PATH,
  payload: path,
});
export const initApp = () => ({
  type: INIT_APP,
});
export const bindButtons = () => ({
  type: BIND_BUTTONS,
});
export const bindMainBtn = () => ({
  type: BIND_MAIN_BTN,
});
export const bindBackBtn = () => ({
  type: BIND_BACK_BTN,
});
export const saveUserInfo = (userData) => ({
  type: SAVE_USER_INFO,
  payload: {
    email: userData?.email,
    role: userData?.role,
    avatarUrl: userData?.avatar_url,
    firstName: userData?.first_name,
    lastName: userData?.last_name,
    surname: userData?.surname,
    company: userData?.company,
    balance: userData?.balance,
    refs: userData?.invited_users,
    subscription: userData?.subscription,
  },
});
export const updateUserInfo = (userData) => ({
  type: UPDATE_USER_INFO,
  payload: {
    newFirstName: userData.firstName,
    newLastName: userData.lastName,
    newSurname: userData.surname,
    newCompany: userData.company,
  },
});
export const goToProfilePage = () => ({
  type: GO_TO_PROFILE_PAGE,
});
export const goToMainPage = () => ({
  type: GO_TO_MAIN_PAGE,
});
